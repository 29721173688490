<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submit"> Сохранить  </el-button>
      <el-button type="primary" @click="submitAndExit"> Сохранить и вернуться </el-button>
    </div>
    <el-form
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="Приоритет" prop="priority">
        <el-input-number v-model="form.priority" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Имя" prop="name">
        <el-input
          v-model="form.name"
          @focus="!form.slug ? handleNameInput(form.name) : ''"
        ></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Фото" prop="image">
        <Uploader
          :limit="1"
          :files="form.image && form.image.original ? [form.image] : []"
          :class="$style.uploader"
          withoutDeleteRequest
          @upload="uploadImage"
        />
      </el-form-item>
      <SeoBlock
        is-with-keywords
        is-add-event
        has-set-default-flag
        :setDefault.sync="form.seo.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage.origin"
        :keywords.sync="form.seo.keywords"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

import { transliterate } from '@/helpers/slug'
import { SEO_TYPES } from '@/constants/seo'

import Uploader from '@/components/moleculs/AddwineUploader.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'

export default {
  SEO_TYPES,
  components: {
    Uploader,
    SeoBlock,
  },
  mixins: [notifications],
  data() {
    return {
      form: {
        priority: 0,
        name: '',
        image: {
          caption: '',
          order: 0,
          original: '',
        },
        seo: {
          setDefault: false,
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      rules: {
        priority: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        image: [
          {
            type: 'object',
            required: true,
            trigger: 'change',
            validator: this.validateImage,
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  async created() {
    await this.getCategory()
  },
  methods: {
    async getCategory() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.CategoriesActions.getOne(
          this.$route.params.id,
        )

      loading.close()

      if (error) {
        return
      }

      this.form = {
        ...value,
        image: {
          ...value.image,
          original: value.image.original,
          order_field: value.image.order,
        },
        seo: value?.seo
          ? {
              ...value?.seo,
              setDefault: value?.seo?.type === SEO_TYPES.TEMPLATE,
              ogImage: value?.seo?.ogImage
                ? {
                    ...value?.seo?.ogImage,
                    origin: value?.seo?.ogImage?.original,
                  }
                : this.form?.seo?.ogImage,
            }
          : {
              ...this.form?.seo,
              setDefault: false,
            },
      }
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const data = {
          ...this.form,
          image: {
            caption: this.form.image.caption,
            origin: this.form.image.original,
            order: !this.form.image.order_field
              ? 1
              : this.form.image.order_field,
          },
          setDefault: this.form?.seo?.setDefault,
          seo: this.form?.seo?.setDefault
            ? null
            : {
                ...this.form.seo,
                type: SEO_TYPES.DEFAULT,
                ogImage: {
                  ...this.form?.seo?.ogImage,
                  caption: this.form?.seo?.title,
                },
              },
        }

        const loading = this.$loading({
          lock: true,
        })

        const { error } =
          await delivery.AddeventServiceCore.CategoriesActions.update(
            this.$route.params.id,
            data,
          )

        loading.close()

        if (error) {
          this.showNotification('Ошибка обновления категории', 'error')
          return
        }

        this.showNotification('Категория успешно отредактирована', 'success')
      })
    },
    async submitAndExit() {
      await this.submit()
      this.$router.go(-1)
    },
    uploadImage(images) {
      this.form.image = images[0]
    },

    validateImage(_, value, callback) {
      value && value.original
        ? callback()
        : callback(new Error('Пожалуйста, добавьте фотографию'))
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    margin-bottom: 1rem;
  }

  .form {
    & > div > label {
      text-align: left;
    }

    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
}
</style>
